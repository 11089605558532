import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./your-stylist.module.scss"
import YourStylistIllustration from "../../assets/images/projects/your-stylist.png"
import YourStylistResult1 from "../../assets/images/projects/your-stylist-result-1.png"
import YourStylistResult2 from "../../assets/images/projects/your-stylist-result-2.png"
import YourStylistResult3 from "../../assets/images/projects/your-stylist-result-3.png"
import YourStylistResult4 from "../../assets/images/projects/your-stylist-result-4.jpg"
import { findIndex, projectsList } from "../../hooks"
import {
  getBigThumbnailByLink,
  useBigProjectsCardsThumbnails,
} from "../../hooks/useBigPreviewProjectsCardsThumbnails"
import projectsStyles from "../projects.module.scss"
import SmallProjectCard from "../../components/SmallProjectCard/SmallProjectCard"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"

const tilesItems = [
  {
    titlePath:
      "projects.your-stylist.technology-background.list.first-item.title",
    textPath:
      "projects.your-stylist.technology-background.list.first-item.text",
  },
  {
    titlePath:
      "projects.your-stylist.technology-background.list.second-item.title",
    textPath:
      "projects.your-stylist.technology-background.list.second-item.text",
  },
  {
    titlePath:
      "projects.your-stylist.technology-background.list.third-item.title",
    textPath:
      "projects.your-stylist.technology-background.list.third-item.text",
  },
  {
    titlePath:
      "projects.your-stylist.technology-background.list.forth-item.title",
    textPath:
      "projects.your-stylist.technology-background.list.forth-item.text",
  },
  {
    titlePath:
      "projects.your-stylist.technology-background.list.fifth-item.title",
    textPath:
      "projects.your-stylist.technology-background.list.fifth-item.text",
  },
  {
    titlePath:
      "projects.your-stylist.technology-background.list.sixth-item.title",
    textPath:
      "projects.your-stylist.technology-background.list.sixth-item.text",
  },
]

const YourStylist: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useBigProjectsCardsThumbnails()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2",
    "section-title-h2"
  )
  const challengeTitleClasses = classNames(
    componentStyles.challengeTitle,
    "large-section-title",
    "section-title-h2"
  )
  const contactPopupContainerClasses = classNames(
    componentStyles.contactPopupContainer,
    "container"
  )
  const solutionDescriptionTitleClasses = classNames(
    componentStyles.solutionDescriptionTitle,
    "large-section-title",
    "section-title-h2"
  )
  const technologyBackgroundTitleClasses = classNames(
    componentStyles.technologyBackgroundTitle,
    "large-section-title",
    "section-title-h2"
  )
  const tilesListClasses = classNames(
    componentStyles.technologyBackgroundTilesList,
    "grid-2"
  )
  const resultsImagesClasses = classNames(componentStyles.resultImages, "container")
  const resultsTitleClasses = classNames(
    componentStyles.resultsTitle,
    "large-section-title",
    "section-title-h2"
  )
  const resultsListClasses = classNames(componentStyles.resultImagesList, "grid-4")
  const projectsTitleClasses = classNames("large-section-title")
  const blockPreview = classNames(projectsStyles.blockPreview, "container")
  const pathname = location.pathname
  const projectUrl = pathname.split("/")[3]
  const projectIndex = findIndex(projectUrl)

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.your-stylist.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.your-stylist.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <div className={componentStyles.bannerWrapper}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                id: "projects.your-stylist.banner.title",
              })}
            </h1>
          </div>
          <div className={componentStyles.bannerImage}>
            <img
              className={componentStyles.bannerImageMainImage}
              src={YourStylistIllustration}
              alt="Illustration with mobile app screens"
              width="860"
              height="722"
            />
          </div>
        </div>
      </section>
      <div className={contactPopupContainerClasses}>
        <div className={componentStyles.contactPopupLeftBlock}>
          <section className={componentStyles.clientCompany}>
            <h2 className="large-section-title section-title-h2">
              {intl.formatMessage({
                id: "projects.your-stylist.client-company.title",
              })}
            </h2>
            <p className={componentStyles.clientCompanyText}>
              {intl.formatMessage({
                id: "projects.your-stylist.client-company.text",
              })}
            </p>
          </section>
          <section className={componentStyles.challenge}>
            <div className={componentStyles.challengeTextBlock}>
              <h2 className={challengeTitleClasses}>
                {intl.formatMessage({
                  id: "projects.your-stylist.challenge.title",
                })}
              </h2>
              <p className={componentStyles.challengeText}>
                {intl.formatMessage({
                  id: "projects.your-stylist.challenge.paragraph-1",
                })}
              </p>
            </div>
          </section>
          <section className={componentStyles.solutionDescription}>
            <div className={componentStyles.solutionDescriptionTextBlock}>
              <h2 className={solutionDescriptionTitleClasses}>
                {intl.formatMessage({
                  id: "projects.your-stylist.solution-description.title",
                })}
              </h2>
              <p className={componentStyles.solutionDescriptionText}>
                {intl.formatMessage({
                  id: "projects.your-stylist.solution-description.text",
                })}
              </p>
            </div>
          </section>
          <section className={componentStyles.technologyBackground}>
            <h2 className={technologyBackgroundTitleClasses}>
              {intl.formatMessage({
                id: "projects.your-stylist.technology-background.title",
              })}
            </h2>
            <p className={componentStyles.technologyBackgroundText}>
              {intl.formatMessage({
                id: "projects.your-stylist.technology-background.text",
              })}
            </p>
            <ul className={tilesListClasses}>
              {tilesItems.map(({ textPath, titlePath }) => (
                <li
                  key={titlePath}
                  className={componentStyles.technologyBackgroundTilesItem}
                >
                  <h4 className={componentStyles.technologyBackgroundTilesTitle}>
                    {intl.formatMessage({
                      id: titlePath,
                    })}
                  </h4>
                  {intl.formatMessage({
                    id: textPath,
                  })}
                </li>
              ))}
            </ul>
          </section>
          <section className={componentStyles.results}>
            <h2 className={resultsTitleClasses}>
              {intl.formatMessage({
                id: "projects.your-stylist.results.title",
              })}
            </h2>
            <p className={componentStyles.resultsText}>
              {intl.formatMessage({
                id: "projects.your-stylist.results.paragraph-1",
              })}
            </p>
            <p className={componentStyles.resultsText}>
              {intl.formatMessage({
                id: "projects.your-stylist.results.paragraph-2",
              })}
            </p>
          </section>
        </div>
        <div className={componentStyles.contactPopup + " " + componentStyles.contactPopupSticky}>
          <div className={componentStyles.contactPopupBackground}></div>
          <h2 className={componentStyles.contactPopupTitle}>
            {intl.formatMessage({
              id: "projects.your-stylist.contact-popup.title",
            })}
          </h2>
          <p className={componentStyles.contactPopupText}>
            {intl.formatMessage({
              id: "projects.your-stylist.contact-popup.paragraph-1",
            })}
          </p>
          <p className={componentStyles.contactPopupHighlight}>
            {intl.formatMessage({
              id: "projects.your-stylist.contact-popup.paragraph-2",
            })}
          </p>
        </div>
      </div>
      <section className={resultsImagesClasses}>
        <div className={componentStyles.contactPopup}>
          <div className={componentStyles.contactPopupBackground}></div>
          <div className={componentStyles.contactPopupTextContainer}>
            <h2 className={componentStyles.contactPopupTitle}>
              {intl.formatMessage({
                id: "projects.your-stylist.contact-popup.title",
              })}
            </h2>
            <p className={componentStyles.contactPopupText}>
              {intl.formatMessage({
                id: "projects.your-stylist.contact-popup.paragraph-1",
              })}
            </p>
            <p className={componentStyles.contactPopupHighlight}>
              {intl.formatMessage({
                id: "projects.your-stylist.contact-popup.paragraph-2",
              })}
            </p>
          </div>
        </div>
        <div className={componentStyles.resultImagesImageWrapper}>
          <div className={resultsListClasses}>
            <img
              src={YourStylistResult1}
              className={componentStyles.resultImagesImage}
              alt={intl.formatMessage({
                id: "projects.your-stylist.results.first-image-alt",
              })}
              width="270"
              height="467"
            />
            <img
              src={YourStylistResult2}
              className={componentStyles.resultImagesImage}
              alt={intl.formatMessage({
                id: "projects.your-stylist.results.second-image-alt",
              })}
              width="270"
              height="467"
            />
            <img
              src={YourStylistResult3}
              className={componentStyles.resultImagesImage}
              alt={intl.formatMessage({
                id: "projects.your-stylist.results.third-image-alt",
              })}
              width="270"
              height="467"
            />
            <img
              src={YourStylistResult4}
              className={componentStyles.resultImagesImage}
              alt={intl.formatMessage({
                id: "projects.your-stylist.results.forth-image-alt",
              })}
              width="270"
              height="467"
            />
          </div>
        </div>
      </section>
      <section className={blockPreview}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.projects.next-project" })}
        </h2>
        {projects
          .filter(project => project.link !== projectUrl)
          .slice(projectIndex, projectIndex + 1)
          .map(({ title, color, link }) => {
            const thumbnail = getBigThumbnailByLink(link, projectsThumbnails)
            return (
              <SmallProjectCard
                key={title}
                title={title}
                thumbnail={thumbnail}
                link={link}
                color={color}
                buttonColor="white"
              />
            )
          })}
      </section>
      <LearnMoreSection />
    </Layout>
  )
}
export default YourStylist
